

































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import RadioButton from "@/components/RadioButton.vue";
import { PostApplication } from "@/components/types";
import Dropdown from "@/components/Dropdown.vue";
import { CountryNames } from "@/services/tenant";
import CurrencyField from "@/components/CurrencyField.vue";
@Component({
  components: { CurrencyField, Dropdown, RadioButton },
})
export default class ClientForm extends Vue {
  @Prop({ required: true }) private countryName!: CountryNames;
  @Prop({ required: true }) private currency!: string;

  formData: Partial<PostApplication> = {};
  private legalEntityTypes: { value: string; label: string }[];
  private companySectors: { value: string; label: string }[];
  private tradingDuration: { value: string; label: string }[];

  constructor() {
    super();
    this.legalEntityTypes = (this.$t("legalEntityTypes") as []).map(
      (value) => ({
        value,
        label: value,
      })
    );
    this.companySectors = (this.$t("companySectors") as []).map((value) => ({
      value,
      label: value,
    }));

    this.tradingDuration = (this.$t("tradingDurationOptions") as []).map(
      (value) => ({
        value,
        label: value,
      })
    );
  }

  @Emit("onDataChange")
  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  onDataChange(_: unknown) {}

  updateData(newData: Partial<PostApplication>) {
    this.formData = {
      ...this.formData,
      ...newData,
    };
    this.onDataChange(this.formData);
  }

  setRegisteredInTenant(yesNo: boolean) {
    this.updateData({
      isBusinessRegisteredInTenant: yesNo,
    });
  }

  setNumberOfEmployees(yesNo: boolean) {
    this.updateData({
      numberOfEmployees: yesNo ? "5 & above" : "0 - 4 employees",
    });
  }

  setBankAccountInTenant(yesNo: boolean) {
    this.updateData({
      isUkBusinessAccount: yesNo,
    });
  }

  setDirectorResidesInTenant(yesNo: boolean) {
    this.updateData({
      isUkResidentDirectors: yesNo,
    });
  }

  setLegalEntityType(option: string) {
    this.updateData({
      legalEntityType: option,
    });
  }

  setCompanySector(option: string) {
    this.updateData({
      companySector: option,
    });
  }

  setTradingDuration(option: string) {
    this.updateData({
      tradingDuration: option,
    });
  }

  setValueOfReceivables(amount: number) {
    this.updateData({
      valueOfReceivables: amount,
    });
  }

  setAnnualRevenue(amount: number) {
    this.updateData({
      annualRevenue: amount,
    });
  }

  setRequestedFunding(amount: number) {
    this.updateData({
      requestFundingAmount: amount,
    });
  }
}
