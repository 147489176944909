


















import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import Spacer from "@/components/Spacer.vue";
import { ChangeEvent } from "@/components/types";

@Component({
  components: { Spacer },
})
export default class CurrencyField extends Vue {
  @Prop({ required: true }) labelKey!: number;
  @Prop({ required: false }) labelProps!: string;
  @Prop({ required: true }) currency!: string;
  @Prop({ required: false }) defaultValue?: number;
  @Prop({ required: false, default: 0 }) minValue!: number;
  @Prop({ required: false, default: 0 }) maxValue!: number;
  state: { value?: number; formattedValue: string } = { formattedValue: "" };

  constructor() {
    super();
    this.state.value = this.defaultValue;
    this.state.formattedValue = this.convertToCurrencyString(this.defaultValue);
  }

  @Emit("onAmountChange")
  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  onAmountChange(option: number) {}

  // In case the tenant request comes back after the fact, lets reformat our field
  @Watch("currency")
  onCurrencyChanged() {
    this.state = {
      ...this.state,
      formattedValue: this.convertToCurrencyString(this.state.value),
    };
  }

  amountChanged(event: ChangeEvent<string>) {
    const amountNumber = this.convertToPlainNumber(event.target.value);
    this.state = {
      ...this.state,
      value: amountNumber,
      formattedValue: this.convertToCurrencyString(amountNumber),
    };
    this.onAmountChange(amountNumber ?? this.defaultValue ?? 0);
  }

  convertToPlainNumber(value: string) {
    return value ? this.localStringToNumber(value) : undefined;
  }

  convertToCurrencyString(value?: number) {
    const currency = this.currency; // https://www.currency-iso.org/dam/downloads/lists/list_one.xml

    const options = {
      maximumFractionDigits: 0,
      currency: currency,
      style: "currency",
      currencyDisplay: "symbol",
    };

    return value ? value.toLocaleString(undefined, options) : "";
  }

  localStringToNumber(s: string) {
    return Number(s.replace(/[^0-9.-]+/g, ""));
  }
}
