import Vue from "vue";
import VueI18n from "vue-i18n";
import enGB from "./en-GB.json";
import nl from "./nl.json";
import enAU from "./en-AU.json";
import enCH from "./en-CH.json";
import deCH from "./de-CH.json";
import frCH from "./fr-CH.json";
import itCH from "./it-CH.json";

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: "en-GB",
  fallbackLocale: "en-GB",
  messages: {
    "en-GB": enGB,
    nl,
    "en-AU": enAU,
    "en-CH": enCH,
    "de-CH": deCH,
    "fr-CH": frCH,
    "it-CH": itCH,
  },
});

export default i18n;
