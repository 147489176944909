













































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import Spacer from "@/components/Spacer.vue";

@Component({
  components: { Spacer },
})
export default class RadioButton extends Vue {
  @Prop({ required: true }) labelKey!: string;
  @Prop({ required: false }) labelProps!: string;
  @Prop({ required: false }) defaultValue?: boolean;
  @Prop({ required: false, default: false }) swapOptions!: boolean;
  @Prop({ required: false, default: "yes" }) yesLabel!: string;
  @Prop({ required: false, default: "no" }) noLabel!: string;
  state: { value?: boolean } = {};

  constructor() {
    super();
    this.state.value = this.defaultValue;
  }

  @Emit("onOptionSelected")
  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  onOptionSelected(option: boolean) {}

  selectOption(yesNo: boolean) {
    this.state = { ...this.state, value: yesNo };
    this.onOptionSelected(yesNo);
  }
}
