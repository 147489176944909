import { render, staticRenderFns } from "./BrokerForm.vue?vue&type=template&id=a5027320&scoped=true&"
import script from "./BrokerForm.vue?vue&type=script&lang=ts&"
export * from "./BrokerForm.vue?vue&type=script&lang=ts&"
function injectStyles (context) {
  
  var style0 = require("./BrokerForm.vue?vue&type=style&index=0&id=a5027320&scoped=true&lang=css&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "a5027320",
  null
  ,true
)

export default component.exports