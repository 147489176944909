























import { Emit, Vue } from "vue-property-decorator";
import { UserType } from "@/components/types";

export default class UserTypeSelector extends Vue {
  @Emit("onUserTypeSelected")
  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  onUserTypeSelected(_: UserType) {}
}
