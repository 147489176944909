




















import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import Spacer from "@/components/Spacer.vue";
import { ChangeEvent } from "@/components/types";

@Component({
  components: { Spacer },
})
export default class Dropdown extends Vue {
  @Prop({ required: true }) labelKey!: string;
  @Prop({ required: false }) labelProps!: string;
  @Prop({ required: false }) defaultValue?: string;
  @Prop({ required: true }) options!: {
    label: string;
    value: string;
  }[];

  state: { value?: string } = {};

  constructor() {
    super();
    this.state.value = this.defaultValue;
  }

  @Emit("onOptionSelected")
  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  onOptionSelected(option: string) {}

  selectOption(event: ChangeEvent<string>) {
    this.state = { ...this.state, value: event.target?.value };
    this.onOptionSelected(event.target?.value);
  }
}
