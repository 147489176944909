import { TenantResponse } from "@/components/types";
import { fetchTenants } from "@/services/api";

export type CountryNames =
  | "UK"
  | "Netherlands"
  | "Switzerland"
  | "Australia"
  | "Collective";

const disallowedLegalEntityTypes = {
  UK: ["Sole trader / partnership"],
  Netherlands: ["Eenmanszaak", "VOF"],
  Switzerland: [],
  Australia: ["Sole trader"],
  Collective: [],
};

const disallowedCompanySectors = {
  UK: ["Finance", "Mining", "Public Administration"],
  Netherlands: ["Mijnbouw", "Finance"],
  Switzerland: [],
  Australia: [],
  Collective: [],
};

export interface Tenant {
  id: string;
  currency: string;
  country: CountryNames;
  disallowedLegalEntityTypes: string[];
  disallowedCompanySectors: string[];
}

let remoteTenants: TenantResponse[] = [];

export async function initializeTenantConfiguration() {
  remoteTenants = await fetchTenants();
}

export function getTenantConfiguration(
  tenantName: string,
  countryName: CountryNames
) {
  const rawTenant = remoteTenants.find(
    (t) => t.id.toLocaleLowerCase() === tenantName.toLocaleLowerCase()
  );

  return {
    id: rawTenant?.id,
    currency: rawTenant?.currencyCode,
    country: countryName,
    disallowedLegalEntityTypes: disallowedLegalEntityTypes[countryName] ?? [],
    disallowedCompanySectors: disallowedCompanySectors[countryName] ?? [],
  } as Tenant;
}
