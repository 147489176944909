import { render, staticRenderFns } from "./Spacer.vue?vue&type=template&id=9fd72dd2&scoped=true&"
var script = {}
function injectStyles (context) {
  
  var style0 = require("./Spacer.vue?vue&type=style&index=0&id=9fd72dd2&scoped=true&lang=css&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "9fd72dd2",
  null
  ,true
)

export default component.exports