import { PostApplication, TenantResponse } from "@/components/types";

const getApiUrl = () => {
  if (window.location.origin.includes("localhost")) {
    return "http://localhost:5001";
  } else if (
    // Nautilus
    window.location.origin.endsWith("tp24services.net") ||
    // Wordpress
    window.location.pathname.endsWith("apply-test/") ||
    window.location.pathname.endsWith("apply-toets/")
  ) {
    return "https://api.tp24services.net/onboarding";
  } else {
    return "https://api.tp24.io/onboarding";
  }
};

export const postApplicationApi = async (
  tenantName: string,
  locale: string,
  data: PostApplication
) => {
  return fetch(getApiUrl() + "/applications", {
    method: "POST",
    body: JSON.stringify({
      ...data,
      tenant: tenantName,
      localization: locale,
    }),
    headers: {
      "Content-Type": "application/json",
      "TP24-Tenant": tenantName,
    },
    mode: "cors",
  })
    .then(function (response) {
      const location = response.headers.get("location");
      if (response.ok && location) {
        return location;
      }
      throw "Failed to get location!";
    })
    .catch(function (error) {
      throw Error(error);
    });
};

export const fetchTenants = async () => {
  const response = await fetch(`${getApiUrl()}/api/tenants`);
  // TODO: Better error handling
  if (!response.ok) {
    throw Error();
  }
  return (await response.json()) as TenantResponse[];
};
