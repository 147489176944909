
















































import { Component, Prop, Vue } from "vue-property-decorator";

import UserTypeSelector from "@/components/UserTypeSelector.vue";
import { ApplicationStep, PostApplication, UserType } from "@/components/types";
import i18n from "@/localization/i18n";
import BrokerForm from "@/components/BrokerForm.vue";
import ClientForm from "@/components/ClientForm.vue";
import ClientFormAU from "@/components/ClientFormAU.vue";
import {
  CountryNames,
  getTenantConfiguration,
  initializeTenantConfiguration,
  Tenant,
} from "@/services/tenant";
import Spacer from "@/components/Spacer.vue";
import { postApplicationApi } from "@/services/api";
import Loader from "@/components/Loader.vue";
import NotApplicableWarning from "@/components/NotApplicableWarning.vue";
import Error from "@/components/Error.vue";
import { bootstrap, setOptions, event } from "vue-gtag";

type CountryCode = "uk" | "nl" | "au" | "ch";
@Component({
  components: {
    Error,
    NotApplicableWarning,
    Loader,
    Spacer,
    ClientForm,
    ClientFormAU,
    BrokerForm,
    UserTypeSelector,
  },
  i18n: i18n,
  name: "ApplicantForm",
})
export default class ApplicantForm extends Vue {
  @Prop({ required: true }) private tenant!: string;
  @Prop({ required: true }) private locale!: string;
  @Prop({ required: true }) private country!: CountryCode;
  @Prop({ required: true }) private googleAnalyticsId!: string;
  @Prop({ required: false, type: Boolean }) private cookiesEnabled:
    | boolean
    | undefined;

  applicationStep: ApplicationStep = "user-type";
  formData: Partial<PostApplication> = {};
  loading = false;
  currency!: string;
  tenantConfigPromise!: Promise<Tenant>;
  countryName: CountryNames = "Collective";

  created() {
    this.$i18n.locale = this.locale ?? "en-GB";
    console.log(`GA cookies: ${this.cookiesEnabled}`);
    console.log(`GA id: ${this.googleAnalyticsId}`);
    if (this.cookiesEnabled) {
      setOptions({
        config: { id: this.googleAnalyticsId }
      });

      bootstrap().then((gtag) => {
        console.log('using gtag!');
      });
    }

    // Placeholder currency until we get the tenant response. Allows the tenant to continue loading in the background.
    switch (this.country) {
      case "uk":
        this.countryName = "UK";
        this.currency = "GBP";
        break;
      case "nl":
        this.countryName = "Netherlands";
        this.currency = "EUR";
        break;
      case "ch":
        this.countryName = "Switzerland";
        this.currency = "CHF";
        break;
      case "au":
        this.countryName = "Australia";
        this.currency = "AUD";
        break;
      default:
        // This is an official test currency.
        this.currency = "XXX";
        break;
    }
    this.tenantConfigPromise = this.setupTenantConfig();
  }

  async setupTenantConfig() {
    try {
      await initializeTenantConfiguration();
    } catch (e) {
      this.applicationStep = "error";
    }

    const tenantConfig = getTenantConfiguration(this.tenant, this.countryName);
    this.currency = tenantConfig.currency;
    // Force an update to make the client form pick up the currency change
    this.$forceUpdate();
    return tenantConfig;
  }

  resetScroll() {
    window.scrollTo(0, 0);
  }

  setUserType(userType: UserType) {
    const eventName =
      userType === "client"
        ? "client_application_started"
        : "introducer_application_started";
    this.submitGtagEvent(eventName);
    this.resetScroll();
    this.formData = {...this.formData, user: userType};
    this.applicationStep = userType as ApplicationStep;
  }

  back() {
    this.resetScroll();
    this.formData = {};
    this.applicationStep = "user-type";
  }

  formDataChanged(formData: Partial<PostApplication>) {
    this.formData = {...this.formData, ...formData};
  }

  submitGtagEvent(eventName: string) {
    if (!this.cookiesEnabled) {
      return;
    }
    try {
      event(eventName, {
        locale: this.locale,
        market: this.country,
      });
    } catch(e: any) {
      console.log(`Could not trigger gtag event ${eventName}`);
    }

  }

  async submitForm() {
    this.loading = true;
    const tenantConfig = await this.tenantConfigPromise;
    this.loading = false;

    this.resetScroll();
    if (!this.isApplicable(tenantConfig)) {
      this.applicationStep = "warning";
      this.submitGtagEvent("client_application_submission_failed");
      return;
    }

    try {
      this.loading = true;
      window.location.href = await postApplicationApi(
        tenantConfig.id,
        this.$i18n.locale,
        this.formData as PostApplication
      );
    } catch (e) {
      this.applicationStep = "error";
    } finally {
      const eventName =
        this.formData.user === "client"
          ? "client_application_submission_successful"
          : "introducer_application_submission_successful";
      this.submitGtagEvent(eventName);
      this.loading = false;
    }
  }

  get isComplete() {
    if (this.formData.user === "broker") {
      return this.formData.brokerAlreadyRegistered !== undefined;
    } else if (
      this.formData.user === "client" &&
      this.countryName === "Australia"
    ) {
      return (
        this.formData.isBusinessRegisteredInTenant !== undefined &&
        this.formData.tradingDuration !== undefined &&
        this.formData.companySector !== undefined &&
        this.formData.legalEntityType !== undefined &&
        this.formData.valueOfReceivables !== undefined &&
        this.formData.annualRevenue !== undefined &&
        this.formData.requestFundingAmount !== undefined
      );
    } else if (this.formData.user === "client") {
      return (
        this.formData.isBusinessRegisteredInTenant !== undefined &&
        this.formData.numberOfEmployees !== undefined &&
        this.formData.isUkBusinessAccount !== undefined &&
        this.formData.isUkResidentDirectors !== undefined &&
        this.formData.tradingDuration !== undefined &&
        this.formData.companySector !== undefined &&
        this.formData.legalEntityType !== undefined &&
        this.formData.valueOfReceivables !== undefined &&
        this.formData.annualRevenue !== undefined &&
        this.formData.requestFundingAmount !== undefined
      );
    }
    return false;
  }

  get isBackButtonEnabled() {
    return this.applicationStep !== "user-type";
  }

  isApplicable(tenantConfig: Tenant) {
    if (this.formData.user === "broker") {
      return this.formData.brokerAlreadyRegistered !== undefined;
    } else if (
      this.formData.user === "client" &&
      this.countryName === "Australia"
    ) {
      return (
        this.formData.isBusinessRegisteredInTenant === true &&
        this.formData.tradingDuration !== undefined &&
        this.formData.companySector &&
        !tenantConfig?.disallowedCompanySectors.includes(
          this.formData.companySector
        ) &&
        this.formData.legalEntityType &&
        !tenantConfig?.disallowedLegalEntityTypes.includes(
          this.formData.legalEntityType
        )
      );
    } else if (
      this.formData.user === "client" &&
      this.countryName === "Switzerland"
    ) {
      return (
        this.formData.isBusinessRegisteredInTenant === true &&
        this.formData.tradingDuration !== "0 - 1" &&
        this.formData.companySector &&
        this.formData.legalEntityType &&
        this.formData.annualRevenue &&
        this.formData.annualRevenue >= 1000000
      );
    } else if (this.formData.user === "client") {
      return (
        this.formData.isBusinessRegisteredInTenant === true &&
        this.formData.numberOfEmployees === "5 & above" &&
        this.formData.isUkBusinessAccount === true &&
        this.formData.isUkResidentDirectors === true &&
        this.formData.tradingDuration !== "0 - 1" &&
        this.formData.tradingDuration !== "1 - 3" &&
        this.formData.companySector &&
        !tenantConfig?.disallowedCompanySectors.includes(
          this.formData.companySector
        ) &&
        this.formData.legalEntityType &&
        !tenantConfig?.disallowedLegalEntityTypes.includes(
          this.formData.legalEntityType
        )
      );
    }
    return false;
  }
}
