









import { Component, Emit, Vue } from "vue-property-decorator";
import RadioButton from "@/components/RadioButton.vue";
import { PostApplication } from "@/components/types";
@Component({
  components: { RadioButton },
})
export default class BrokerForm extends Vue {
  formData: Partial<PostApplication> = {};

  @Emit("onDataChange")
  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  onDataChange(_: unknown) {}

  setAlreadyRegistered(yesNo: boolean) {
    this.formData = {
      ...this.formData,
      brokerAlreadyRegistered: yesNo,
    };
    this.onDataChange(this.formData);
  }
}
